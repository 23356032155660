<template>
  <div class="user-management">
    <div class="search-top">
      <el-button type="primary" size="mini" @click="goToAdd"
        >新增用户</el-button
      >
    </div>
    <div style="clear: both"></div>
    <div class="search-bar">
      <el-form
        :inline="true"
        :model="listQuery"
        ref="departmentInfo"
        size="small"
      >
        <!-- <el-form-item label="部门" label-width="60px">
          <el-select
            v-model="listQuery.departmentId"
            placeholder="请选择"
            style="width: 150px"
            clearable
          >
            <el-option
              v-for="(item, index) in departmentData"
              :key="index"
              :label="item.departmentName"
              :value="item.departmentId"
            ></el-option>
           
          </el-select>
        </el-form-item> -->

        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.userName"
            placeholder="请输入用户名"
             maxlength="32"
            style="width: 200px"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.realName"
            placeholder="真实姓名"
            style="width: 200px"
            maxlength="64"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.userPhone"
            placeholder="请输入电话"
            maxlength="11"
            style="width: 200px"
          >
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="mini" @click="searchBtn"
            >搜索</el-button
          >
          <el-button size="mini" @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
      border
    >
      <el-table-column type="index" label="序号" width="65"> </el-table-column>
      <el-table-column prop="userName" label="用户名" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="realName" label="真实姓名" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="sex" label="性别" width="50">
        <template slot-scope="scope">
          <span>{{ scope.row.sex | sexFilters }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="departmentName"
        label="部门名称"
        show-overflow-tooltip
      >
      </el-table-column> -->
      <el-table-column prop="userPhone" label="手机号"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="170">
      </el-table-column>
      <el-table-column prop="lastLogin" label="最后登录" width="170">
      </el-table-column>
      <el-table-column prop="visitNumber" label="访问次数" width="80">
      </el-table-column>
      <el-table-column label="操作" fixed="right" v-slot="scope" width="180">
        <div class="option-list-box">
          <el-link
            :underline="false"
            type="primary"
            @click="goToEdit(scope.row)"
            >编辑</el-link
          >
          <el-link
            :underline="false"
            type="warning"
            class="ml10r"
            @click="resetPassword(scope.row)"
            >重置密码</el-link
          >
          <el-link
            :underline="false"
            type="danger"
            class="ml10r"
            @click="deleteOption(scope.row)"
            >删除</el-link
          >
        </div>
      </el-table-column>
    </el-table>
    <div class="page-box mt20r">
      <el-pagination
        background
        @current-change="getTableList"
        :current-page.sync="listQuery.current"
        :page-size="listQuery.size"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 编辑用户信息弹窗  -->
    <el-dialog :visible.sync="editUserDialogVisible" width="1100px">
      <span slot="title" v-if="editType == 'add'">
        新增用户信息，初始密码为123456
      </span>
      <span slot="title" v-if="editType == 'edit'">
        编辑用户信息，初始密码为123456
      </span>
      <editUser
        v-if="editUserDialogVisible"
        :edit-type="editType"
        :edit-user-id="editUserId"
        ref="editUser"
        @success="editSuccessHandel"
      ></editUser>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBackstageUserPage,
  DataDelete,
  resetPassword,
  getSysDepartmentList,
} from "../../../api/authorityManagement";

import editUser from "./components/editUser.vue";
export default {
  components: {
    editUser,
  },
  data() {
    return {
      editUserDialogVisible: false,
      listQuery: {
        current: 1,
        size: 10,
        userName: "", //用户名
        userPhone: "", //用户手机
        realName: "", //真实姓名
        //departmentId: "",
      },
      total: 0,
      tableData: [],
      departmentData: [], //获取部门
      editUserId: "", //编辑的用户ID
      editType: "add", //add 新增，edit 修改
    };
  },
  mounted() {
    this.getTableList();
    //  this.getDepartmentData();
  },

  filters: {
    sexFilters(val) {
      let result = "--";
      if (val == 0) {
        result = "女";
      }
      if (val == 1) {
        result = "男";
      }
      return result;
    },
  },
  methods: {
    //确定编辑
    confirmEditHandle() {
      this.$refs.editUser.confirmEdit();
    },
    //编辑成功事件
    editSuccessHandel() {
      this.editUserDialogVisible = false;
      this.getTableList();
    },
    // 获取部门
    getDepartmentData() {
      getSysDepartmentList().then((res) => {
        if (res.code == 1) {
          this.departmentData = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange() {},
    //列表查询
    getTableList(val) {
      this.listQuery.pages = val;
      this.getTableData();
    },
    getTableData() {
      getBackstageUserPage(this.listQuery).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //搜索按钮
    searchBtn() {
      this.listQuery.pages = 1;
      this.getTableList();
    },
    // 重置
    resetBtn() {
      this.listQuery.keyWord = "";
      this.listQuery.userName = ""; //用户名
      this.listQuery.realName = ""; //真实姓名
      this.listQuery.userPhone = ""; //用户手机
      // this.listQuery.departmentId = "";
      this.getTableData();
    },
    goToAdd() {
      // this.$router.push({
      //   name: "addUser",
      //   query: {
      //     isAdd: 1,
      //   },
      // });

      this.editUserId = "";
      this.editType = "add";
      this.editUserDialogVisible = true;
      // this.$nextTick(() => {
      //   this.$refs.editUser.reset();
      // });
    },
    goToEdit(row) {
      // this.$router.push({
      //   name: "editUser",
      //   query: {
      //     userId: row.userId,
      //     isAdd: 2,
      //   },
      // });
      this.editUserId = row.userId;
      this.editType = "edit";
      this.editUserDialogVisible = true;
      // this.$nextTick(() => {
      //   console.log(this.editUserId)
      //   this.$refs.editUser.reset();
      // });
    },

    //删除用户
    deleteOption(row) {
      this.$confirm("确定要删除此用户", "删除用户", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            userId: row.userId,
          };
          DataDelete(data).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getTableList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    //修改密码
    resetPassword(row) {
      this.$confirm("确定重置密码？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          resetPassword({ userId: row.userId }).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "密码重置成功",
              });
              this.getTableList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.user-management {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
}
</style>